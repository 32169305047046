





















































































import Vue from "vue";
import Component from "vue-class-component";
import { Button, Icon, Cell, Toast, Dialog, Field } from "vant";

@Component({
  components: {
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [Dialog.Component.name]: Dialog.Component,
    [Field.name]: Field
  }
})
export default class BusinessAdd extends Vue {
  flow = {
    id: 0,
    name: "",
    userId: 0,
    phone: 0,
    userName: "",
    managerId: 0,
    managerName: "",
    managerPhone: "",
    servicePhone: "",
    service: 0,
    chargeProfitConfig: "",
    chargeProfitConfigRaw: [],
    replaceProfitConfig: "",
    replaceProfitConfigRaw: [],
    goodsProfitBaseConfig: "",
    goodsProfitBaseConfigRaw: [],
    permissions: "",
    permissionStr: ""
  };
  dialog = {
    show: false,
    keyword: ""
  };
  managerDialog = {
    show: false,
    keyword: ""
  };
  created() {
    const flowStr = localStorage.myCacheBusinessAddFlow;
    if (flowStr) {
      this.flow = JSON.parse(flowStr);
    }
  }
  get isReady() {
    if (!this.flow.service) return false;
    if ((this.flow.service & 3) == 3) {
      if (!this.flow.chargeProfitConfig) {
        return false;
      }
    }
    if ((this.flow.service & 244) == 244) {
      if (!this.flow.replaceProfitConfig) {
        return false;
      }
    }
    if (!this.flow.name) return false;
    if (!this.flow.managerPhone) return false;
    if (!this.flow.permissions) return false;
    return true;
  }
  save() {
    const postData = {
      name: this.flow.name,
      phone: this.flow.phone,
      managerPhone: this.flow.managerPhone,
      servicePhone: this.flow.servicePhone,
      service: this.flow.service,
      chargeProfitConfig: JSON.stringify(this.flow.chargeProfitConfig || []),
      replaceProfitConfig: JSON.stringify(this.flow.replaceProfitConfig || []),
      goodsProfitBaseConfig: this.flow.goodsProfitBaseConfig,
      permissions: this.flow.permissions,
      businessApplyId: this.flow.id
    };
    return this.$axios.post("/api/manage/business/new", postData).then(() => {
      localStorage.removeItem("myCacheBusinessAddFlow");
      Dialog.alert({
        message: "添加成功"
      }).then(() => {
        localStorage.removeItem("myCacheBusinessAddFlow");
        this.$router.go(-1);
      });
    });
  }
  clearAndReturn() {
    Dialog.confirm({
      message: "是否保留当前编辑的内容?",
      confirmButtonText: "是",
      cancelButtonText: "否"
    })
      .then(() => {
        this.$router.go(-1);
      })
      .catch(() => {
        localStorage.removeItem("myCacheBusinessAddFlow");
        this.$router.go(-1);
      });
  }
  toBusinessInfo() {
    if (!this.flow.userId) {
      this.dialog.show = true;
      return;
    }
    this.toFlow("/web/manager/business/edit?inBusinessAddFlow=1");
  }
  findUserAndToBusiness() {
    this.dialog.show = false;
    this.$axios.post("/api/manage/findUser", { keyword: this.dialog.keyword }).then(e => {
      const data = e.data.data;
      if (!data.name) {
        Toast.fail("用户未实名");
        return;
      }
      this.flow.userId = data.id;
      this.flow.phone = data.phone;
      this.flow.servicePhone = data.phone;
      this.flow.userName = data.name;
      this.toBusinessInfo();
    });
  }
  findUserAndSetManager() {
    this.managerDialog.show = false;
    this.$axios.post("/api/manage/findUser", { keyword: this.managerDialog.keyword }).then(e => {
      const data = e.data.data;
      if (!data.name) {
        Toast.fail("管理员未实名");
        return;
      }
      this.flow.managerId = data.id;
      this.flow.managerName = data.name;
      this.flow.managerPhone = data.phone;
      this.saveFlow();
    });
  }
  toChargeProfitConfig() {
    if (!this.flow.userId) {
      Toast.fail("请先填写运营商信息");
      return;
    }
    this.toFlow("/web/manager/profit/config/edit?inBusinessAddFlow=1&mode=0");
  }
  toReplaceProfitConfig() {
    if (!this.flow.userId) {
      Toast.fail("请先填写运营商信息");
      return;
    }
    this.toFlow("/web/manager/profit/config/edit?inBusinessAddFlow=1&mode=1");
  }
  toGoodsProfitBaseConfig() {
    if (!this.flow.userId) {
      Toast.fail("请先填写运营商信息");
      return;
    }
    this.toFlow("/web/manager/goods/manager_goods_base_profit?inBusinessAddFlow=1");
  }
  toPermission() {
    if (!this.flow.userId) {
      Toast.fail("请先填写运营商信息");
      return;
    }
    this.toFlow("/web/manager/business/permission_edit/0?inBusinessAddFlow=1");
  }
  toFlow(url: string) {
    this.saveFlow();
    location.replace(url);
  }
  saveFlow() {
    localStorage.myCacheBusinessAddFlow = JSON.stringify(this.flow);
  }
}
